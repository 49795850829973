import React from "react";
import { Link } from "gatsby";
import Logo from "images/logo";
import styles from "./Navbar.module.scss";

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div>
        <Link to="/" className={styles.link}>
          Home
        </Link>
        <Link to="/projects" className={styles.link}>
          Projects
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
