import React from "react";
import PropTypes from "prop-types";
import Navbar from "./Navbar.js";
import styles from "./layout.module.scss";

const Layout = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <Navbar />
      <div className={styles.container}>
        <main>{children}</main>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
